<template>
    <div class="planning-onboarding">
        <div class="view-content">
            <action-bar title="lançamentos financeiros" />

            <div v-if="!step" class="container">
                <video controls>
                    <source src="@/assets/videos/presentation-video.mp4" type="video/mp4" />
                    Seu navegador não suporta este formato de vídeo
                </video>
            </div>

            <div class="btn-container">
                <basic-button :label="btnLabel" @click="step++" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import BasicButton from '../components/buttons/BasicButton.vue'
import ActionBar from '../components/ActionBar.vue'

const step = ref(0)

const btnLabel = computed(() => (step.value ? 'Finalizar' : 'Iniciar'))
</script>

<style lang="scss" scoped>
.planning-onboarding {
    .btn-container {
        width: 100%;
        margin-top: 12px;
        @include flex-center();
    }
}
</style>
