<template>
    <div class="month-selector" :style="{ '--color': color, width: ruleLenght > 1 ? '100%' : '200px', ...styles }">
        <a
            data-cy="change-month-back"
            :class="[
                { hide: hideButtons[0] || this.hideLeftBtn },
                { 'move-left': this.hideLeftBtn },
                { 'small-screen': ruleMode },
            ]"
            class="icon ion-ios-arrow-back"
            @click="changeMonth(-1)"
            ><ion-icon name="chevron-back-outline"></ion-icon
        ></a>

        <div v-if="ruleLenght > 1" class="rule"></div>

        <div class="period-wrapper">
            <div
                v-for="(n, index) in ruleLenght"
                :key="n"
                class="data-label"
                :class="[
                    { selected: selected === n },
                    { _rule: ruleLenght > 1 },
                    { clickable: active !== n && selected === n },
                ]"
                @click="selectMonth(n - active, n)"
            >
                {{ period(index) }}
            </div>
        </div>

        <a
            data-cy="change-month-forward"
            :class="[
                { hide: hideButtons[1] || this.hideRightBtn },
                { 'move-right': this.hideRightBtn },
                { 'small-screen': ruleMode },
            ]"
            class="icon ion-ios-arrow-forward"
            @click="changeMonth(1)"
            ><ion-icon name="chevron-forward-outline"></ion-icon
        ></a>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { mainStore } from '../../store'

import formatDate from '../../common/formatDate'

export default {
    name: 'MonthSelector',

    props: {
        hideButtons: {
            type: Array,
            default: () => [false, false],
        },
        color: {
            type: String,
            default: '#9FA5B1',
        },
        ruleLenght: {
            type: Number,
            default: 1,
        },
        shift: {
            type: Number,
            default: 0,
        },
        changePeriod: {
            type: Boolean,
        },
    },

    data() {
        return {
            selected: 1 + this.shift,
            active: 1 + this.shift,
            defaultPosition: 0,
            lastPeriod: null,
        }
    },

    computed: {
        ...mapState(mainStore, ['_date_selected', '_date']),

        hideLeftBtn() {
            return this.ruleLenght > 1 && this.selected === 1
        },

        hideRightBtn() {
            return this.ruleLenght > 1 && this.selected === this.ruleLenght
        },

        styles() {
            return {
                '--position': `${this.defaultPosition}px`,
            }
        },

        smallScreen() {
            return this.screenWidth() < 701
        },

        ruleMode() {
            return this.ruleLenght > 1 && this.smallScreen
        },
    },

    methods: {
        ...mapActions(mainStore, ['setSelectedDate']),

        reset() {
            const { inicio, fim, selected, active, defaultPosition } = this.lastPeriod

            this.setSelectedDate({ inicio, fim })

            this.selected = selected
            this.active = active
            this.defaultPosition = defaultPosition

            this.lastPeriod = null
        },

        moveRule(value) {
            if (this.selected <= this.ruleLenght && this.selected >= 1) {
                this.defaultPosition += 160 * value
                this.selected -= value
            }
        },

        changeMonth(add) {
            if (this.ruleLenght > 1) this.moveRule(add * -1)
            else this.selectMonth(add)
        },

        selectMonth(add, active = 1) {
            this.setSelectedDate(formatDate.changeMonth(this._date_selected.inicio, add))
            this.$emit('change-month', add)

            this.active = active
        },

        period(add) {
            if (this.ruleLenght === 1) return this.$moment(this._date_selected.inicio).format('MMMM YYYY')
            return this.$moment(this._date.inicio)
                .add(add - this.shift, 'M')
                .format('MMMM YYYY')
        },

        screenWidth() {
            return window.innerWidth
        },
    },

    watch: {
        ruleLenght: {
            immediate: true,
            handler() {
                this.defaultPosition = this.ruleLenght > 1 ? 240 - 160 * this.shift : 0
            },
        },

        screenWidth: {
            immediate: true,
            handler(newValue) {
                if (this.ruleMode) this.defaultPosition = this.smallScreen ? -80 : 50
            },
        },

        lastPeriod: {
            immediate: true,
            handler(newVal) {
                if (newVal && !newVal?.selected) {
                    this.lastPeriod = {
                        ...newVal,
                        selected: this.selected,
                        defaultPosition: this.defaultPosition,
                        active: this.active,
                    }
                }
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.month-selector {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    .period-wrapper {
        position: relative;
        width: 100%;
        max-width: 650px;
        display: flex;
        transform: translateX(var(--position));
        transition: all 0.5s;

        .data-label {
            font-weight: 500;
            min-width: 160px;
            text-transform: capitalize;
            opacity: 0.5;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 2px;

            &._rule {
                text-transform: uppercase;
                pointer-events: none;

                &.clickable {
                    cursor: pointer;
                    pointer-events: all;

                    &:hover {
                        opacity: 1;
                        transform: scale(1.1);
                    }
                }
            }
            &.selected {
                opacity: 1;

                &._rule {
                    transform: scale(1.1);
                }
            }
        }
    }

    .rule {
        &::after,
        &::before {
            background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            content: '';
            height: 55px;
            position: absolute;
            width: 200px;
            z-index: 2;
            opacity: 1;
        }

        &::after {
            right: -20px;
            top: -16px;
            transform: rotateZ(180deg);
        }

        &::before {
            left: -20px;
            top: -16px;
        }
    }

    .icon {
        height: 18.57px;
        width: 18.57px;
        color: var(--color);
        text-align: center;
        position: relative;
        z-index: 3;

        &.hide {
            opacity: 0;
            pointer-events: none;
        }

        &.small-screen {
            position: absolute;

            &:first-child {
                left: 0px;
            }

            &:last-child {
                right: 0px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .rule {
        &::after,
        &::before {
            width: 60px !important;
        }
    }
}
</style>
