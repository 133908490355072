<template>
    <div class="flex row center">
        <v-btn
            v-show="show.currency"
            elevation="0"
            class="unity-button"
            :style="value === 'currency' ? { border: '2px solid #028FFF' } : null"
            @click="$emit('selectUnity', 'currency')"
        >
            <h1>{{ prefix }}</h1>
        </v-btn>

        <v-btn
            v-show="show.percentage"
            elevation="0"
            class="unity-button"
            :style="value === 'percentage' ? { border: '2px solid #028FFF' } : null"
            @click="$emit('selectUnity', 'percentage')"
        >
            <h1>%</h1>
        </v-btn>
    </div>
</template>

<script>
import util from '../../common/util'

export default {
    name: 'UnityRadio',

    emits: ['selectUnity'],

    props: {
        type: {
            type: String,
            required: true,
        },
        show: {
            type: Object,
            default: () => {
                return { currency: true, percentage: true }
            },
        },
    },

    data() {
        return {
            value: this.type,
            prefix: util.returnCurrencyPrefix().prefix,
        }
    },

    watch: {
        type: function (type) {
            this.value = type
        },
    },
}
</script>

<style lang="scss" scoped>
.center {
    margin: 0;
    align-items: center;
    flex-wrap: nowrap;
}
.unity-button {
    background-color: #f5f5f5;
    height: 42px !important;
    width: 60px;
    border-radius: 25px;
    margin-right: 10px;
}

h1 {
    font-weight: 500;
    font-size: 14px;
    color: #8c8c8d;
    text-align: center;
    padding-top: 2px;
    line-height: 38px;
    vertical-align: middle;
}
</style>
