<template>
    <div class="form-table">
        <div id="form-list" :style="{ overflowY: rows > 5 ? 'scroll' : 'hidden' }">
            <div class="header-grid">
                <div v-for="(header, index) in headers" :key="index" class="header">{{ header }}</div>
            </div>

            <form-row
                v-for="n in rows"
                :ref="`row-${n}`"
                :key="n"
                :transaction-type="step > 1 ? 'expenses' : 'revenues'"
                :category-type="categoryTypes[step - 1]"
                :onboarding-client="onboardingClient"
                @update-transaction="(data) => $emit('update-transaction', { ...data, id: `step-${step}-${n}` })"
            />
        </div>

        <div class="new-line-btn">
            <button @click="rows++">
                <v-icon class="icon" size="14" dark> fas fa-plus </v-icon>
                nova linha
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia'

import FormRow from './FormRow.vue'

import { mainStore } from '../../../../../store'
import { sheetPlanningCategories } from '../../../../../common/lists'

export default {
    name: 'FormTable',

    components: {
        FormRow,
    },

    props: {
        step: {
            type: Number,
            required: true,
        },
        onboardingClient: {
            type: Boolean,
        },
        defaultRows: {
            type: Number,
            default: 5,
        },
    },

    data() {
        return {
            rows: this.defaultRows,
            categoryTypes: [undefined, 'essential', 'noessential', 'debts', 'investiment'],
        }
    },

    computed: {
        ...mapState(mainStore, ['filteredCategories', 'selectedUser', 'newGroupedCategories']),

        headers() {
            const array = ['data', 'categoria', 'valor', 'descrição', 'rep', 'parc']
            // if (this.step > 1) array.push('pago')
            // else array.push('receb')

            return array
        },
    },

    methods: {
        preFillForms() {
            let categories = []

            if (this.step === 1)
                categories = this.filteredCategories('revenues', this.selectedUser).filter((c) =>
                    sheetPlanningCategories.revenues.includes(c.name),
                )
            else {
                const groupedCategories = this.newGroupedCategories(true, true, this.selectedUser)

                switch (this.categoryTypes[this.step - 1]) {
                    case 'essential':
                        categories = groupedCategories[0].data.filter((c) =>
                            sheetPlanningCategories.fixed.includes(c.name),
                        )
                        break
                    case 'noessential':
                        categories = groupedCategories[1].data.filter((c) =>
                            sheetPlanningCategories.variable.includes(c.name),
                        )
                        break
                    default:
                        break
                }
            }

            categories.forEach((c, index) => {
                if (this.$refs[`row-${index + 1}`])
                    Object.assign(this.$refs[`row-${index + 1}`][0].formData, { name: c.name, category: c._id })
            })
        },

        pushUploadedData(list = []) {
            list.forEach((item, index) => {
                if (index + 1 > this.rows) this.rows++

                setTimeout(() => {
                    if (this.$refs[`row-${index + 1}`]) Object.assign(this.$refs[`row-${index + 1}`][0].formData, item)
                }, 100)
            })
        },
    },

    watch: {
        rows: {
            handler() {
                let scrollableDiv = document.getElementById('form-list')
                scrollableDiv.scrollTo(0, scrollableDiv.scrollHeight)
            },
        },
    },

    mounted() {
        if (this.onboardingClient) this.preFillForms()
    },
}
</script>

<style lang="scss" scoped>
.form-table {
    #form-list {
        height: 290px;
        overflow-x: hidden;
        .header-grid {
            display: grid;
            grid-template-columns: 140px 2fr 150px 2fr 50px 50px;
            column-gap: 8px;
            margin: 16px 0 8px;

            .header {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                color: $dark-grey;
                white-space: nowrap;
            }
        }
    }

    .new-line-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;

        button {
            background-color: #a0d2ff;
            color: white;
            font-size: 12px;
            font-weight: 400 !important;
            border-radius: 40px;
            padding: 12px 16px;

            .icon {
                margin-right: 12px;
            }
        }
    }
}
</style>
