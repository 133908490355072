<template>
    <login-container>
        <!-- LOGIN -->

        <transition name="fade">
            <form id="login" class="screen-container" v-show="showLogin" @submit.prevent="login(email, password)">
                <img style="display: none" :src="$variables.brandIcon2" />

                <div v-if="disabled" class="loader"></div>

                <span v-else-if="!errorMessage" class="guide-message"> {{ ottoMsg }} </span>

                <span v-else class="guide-message error-message"> {{ errorMessage }} </span>

                <stacked-input
                    type="email"
                    label="Email de cadastro"
                    :errorValidation="errors.email.error"
                    :errorMsg="errors.email.message"
                    v-model="email"
                    :required="true"
                    ph="Ex: voce@provedor.com.br"
                    name="email-login"
                />

                <stacked-input
                    type="password"
                    label="Senha"
                    :showEyeButton="true"
                    v-model="password"
                    :required="true"
                    :errorValidation="errors.password.error"
                    :errorMsg="errors.password.message"
                    name="password-login"
                />

                <basic-button data-cy="submit-login" type="submit" label="Entrar" width="100%" />

                <a
                    data-cy="toggle-recover"
                    type="button"
                    id="forgot-password"
                    @click="$router.push('/account-recovery')"
                >
                    Esqueci minha senha
                </a>

                <div v-if="$variables.socialLoginEnabled" class="social-login-title">
                    <hr />
                    login com redes sociais
                    <hr />
                </div>

                <social-login-buttons v-if="$variables.socialLoginEnabled" @social-login="handleSocialLogin" />

                <basic-button
                    data-cy="toggle-register"
                    type="button"
                    :reverse="true"
                    @click="$router.push('/cadastro')"
                    width="100%"
                >
                    Ainda não cadastrei uma conta
                </basic-button>
            </form>
        </transition>

        <!-- LOGIN COM REDES SOCIAIS -->

        <transition name="fade">
            <div id="recover" class="screen-container" v-show="showSocial">
                <div v-if="disabled" class="loader"></div>

                <span v-else-if="!errorMessage" class="guide-message"> {{ ottoMsg }} </span>

                <span v-else class="guide-message error-message"> {{ errorMessage }} </span>

                <a type="button" @click="toggleLogin">Me cadastrei usando e-mail e senha</a>

                <a type="button" @click="$router.push('/cadastro')">Ainda não cadastrei uma conta</a>
            </div>
        </transition>

        <transition name="fade">
            <div id="planner-login-select" class="screen-container" v-show="showPlannerLoginSelect">
                <div v-if="disabled" class="loader"></div>

                <span v-else-if="!errorMessage" class="guide-message"> {{ ottoMsg }} </span>

                <span v-else class="guide-message error-message"> {{ errorMessage }} </span>

                <stacked-select
                    style="width: 100%"
                    label="Cliente"
                    :required="true"
                    :model="clients"
                    :error-validation="errors.client.error"
                    :disabled="disabled"
                    @change="(value) => (client = value)"
                />

                <basic-button
                    data-cy="planner-login"
                    type="button"
                    @click="login(socialLoginData.email || email, socialLoginData.password || password)"
                    width="100%"
                >
                    Ok
                </basic-button>
            </div>
        </transition>
    </login-container>
</template>

<script>
import { signInWithPopup, GoogleAuthProvider, OAuthProvider, FacebookAuthProvider } from 'firebase/auth'
import { mapState } from 'pinia'
import { mainStore } from '../store'

import AlertHeader from '../components/AlertHeader.vue'

import SwitchingEye from '../components/icons/SwitchingEye.vue'
import StackedInput from '../components/inputs/StackedInput.vue'
import BasicButton from '../components/buttons/BasicButton.vue'
import LoginContainer from '../containers/LoginContainer.vue'
import SocialLoginButtons from '../components/SocialLoginButtons.vue'
import StackedSelect from '../components/inputs/StackedSelect.vue'

import authenticate from '../services/auth.service'

export default {
    name: 'Login',

    components: {
        AlertHeader,
        SwitchingEye,
        StackedInput,
        BasicButton,
        LoginContainer,
        SocialLoginButtons,
        StackedSelect,
    },

    data() {
        return {
            ottoMsg: 'Olá! Faça login conforme a opção que utilizou ao realizar seu cadastro:',
            showLogin: false,
            showSocial: false,
            showPlannerLoginSelect: false,

            email: '',
            password: '',

            clients: [],
            client: '',

            socialLoginData: {
                email: '',
                password: '',
            },

            errorMessage: '',
            errors: {
                email: {
                    error: false,
                    message: undefined,
                },
                password: {
                    error: false,
                    message: undefined,
                },
                name: {
                    error: false,
                    message: undefined,
                },
                client: {
                    error: false,
                },
            },
        }
    },

    mounted() {
        setTimeout(() => {
            this.showLogin = true
        }, 300)
    },

    methods: {
        showError(message) {
            this.errorMessage = message
            this.ottoMsg = null
        },

        autoLogin(email, password) {
            if (email && password) {
                this.login(email, password)
            }
        },

        login(email, password) {
            let credentials = {
                email,
                password,
            }
            this.errors.email.error = this.errors.password.error = false
            this.errors.email.message = this.errors.password.message = undefined

            if (!credentials.email) this.errors.email.error = true

            if (!credentials.password) this.errors.password.error = true

            if (this.showPlannerLoginSelect) {
                if (!this.client) this.errors.client.error = true
                else credentials.clientId = this.client
            }

            if (credentials.email && credentials.password) {
                credentials.email = credentials.email.toLowerCase()

                authenticate
                    .authenticate(credentials)
                    .then((resp) => {
                        if (resp?.length) {
                            this.clients = resp
                            this.togglePlannerLogin()
                        } else return resp
                    })
                    .then((status) => {
                        switch (status) {
                            case 500:
                                this.showError('Erro ao tentar efetuar login')
                                this.errors.password.error = this.errors.email.error = true
                                this.errors.email.message = this.errors.password.message = ''
                                break
                            case 502:
                                this.showError('Usuário não registrado')
                                this.errors.password.error = this.errors.email.error = true
                                this.errors.email.message = this.errors.password.message = ''
                                break
                            case 403:
                                if (this.toggleSocial) this.showError('Usuário não registrado')
                                this.showError(
                                    'Opa, tem algo errado com seu e-mail ou sua senha! Reveja as informações.',
                                )

                                this.errors.password.error = this.errors.email.error = true
                                this.errors.email.message = this.errors.password.message = ''
                                break
                            case 504:
                                this.showError('Acesso de usuário restrito')
                                this.errors.password.error = this.errors.email.error = true
                                this.errors.email.message = this.errors.password.message = ''
                                break
                            default:
                                this.showError('')
                        }
                    })
            } else this.showError('Observe que todos os campos abaixo são obrigatórios, ok?', 4)
        },

        toggleSocial() {
            this.showLogin = false
            this.errorMessage = null

            setTimeout(() => {
                this.ottoMsg = 'Então entre com a rede social que utilizou ao realizar o seu cadastro'
                this.showSocial = true
            }, 300)
        },

        toggleLogin() {
            this.showSocial = false
            this.errorMessage = this.email = this.password = null
            this.socialLoginData = { email: '', password: '' }

            setTimeout(() => {
                this.ottoMsg = 'Olá! Cadastrou sua conta usando e-mail e senha? Insira-os aqui:'
                this.showLogin = true
            }, 300)
        },

        togglePlannerLogin() {
            this.showSocial = this.showLogin = false

            setTimeout(() => {
                this.ottoMsg = 'Selecione o cliente:'
                this.showPlannerLoginSelect = true
            }, 300)
        },

        getFbEmail(providerData, providerId) {
            return `${providerData.find((data) => data.providerId === providerId).uid}@ottoSocial.com`
        },

        setUserLogin(firebaseUser, providerId) {
            let { uid, email, providerData } = firebaseUser

            if (providerId === 'facebook.com') email = this.getFbEmail(providerData, providerId)

            this.socialLoginData.email = email
            this.socialLoginData.password = uid
            this.login(email, uid)
        },

        handleError(error) {
            const errorMessage = error.message

            if (errorMessage.includes('account-exists-with-different-credential')) {
                this.errorMessage = 'Opa.. Já existe uma conta cadastrada com este e-mail!'
                return
            }

            this.errorMessage = errorMessage
        },

        handleSocialLogin(type) {
            switch (type) {
                case 'facebook':
                    this.facebookLogin()
                    break
                case 'google':
                    this.googleLogin()
                    break
                case 'apple':
                    this.appleLogin()
                    break
                default:
            }
        },

        googleLogin() {
            signInWithPopup(this.$firebase.auth, this.$firebase.googleProvider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result)
                    const token = credential.accessToken
                    // The signed-in user info.f
                    const user = result.user

                    this.setUserLogin(user, result.providerId)
                })
                .catch(this.handleError)
        },

        appleLogin() {
            signInWithPopup(this.$firebase.auth, this.$firebase.appleProvider)
                .then((result) => {
                    // The signed-in user info.
                    const user = result.user

                    // Apple credential
                    const credential = OAuthProvider.credentialFromResult(result)
                    const accessToken = credential.accessToken
                    const idToken = credential.idToken

                    this.setUserLogin(user, result.providerId)
                })
                .catch(this.handleError)
        },

        facebookLogin() {
            signInWithPopup(this.$firebase.auth, this.$firebase.facebookProvider)
                .then((result) => {
                    // The signed-in user info.
                    const user = result.user

                    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                    const credential = FacebookAuthProvider.credentialFromResult(result)
                    const accessToken = credential.accessToken

                    this.setUserLogin(user, result.providerId)
                })
                .catch(this.handleError)
        },
    },

    computed: {
        ...mapState(mainStore, ['disabled', 'user']),

        styles() {
            return {
                '--background': `url(${this.$variables.background})`,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.loader {
    font-size: 20px;
    margin: 20px 0;
}

.social-login-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $light-grey;
    text-transform: uppercase;
    font-size: 14px;
    margin: 0 0 24px 0;
    text-align: center;
    letter-spacing: 0.7px;

    hr {
        width: 40px;
        margin: 0 12px 0 0;
        border-color: $lighter-grey;

        &:last-child {
            margin: 0 0 0 12px;
        }
    }
}

.screen-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 400px;
    position: relative;
    padding: 24px 40px 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.guide-message {
    font-weight: 400;
    margin-bottom: 15px;
    color: #34495e;
    font-weight: 500;
    text-align: center;
    padding: 0 5px;
    line-height: 1.35rem;
}

#forgot-password {
    margin: 30px 0;
    width: 100%;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.7px;
}

.error-message {
    color: firebrick;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    padding: 0 10px;
}

p {
    font-weight: 500;
}

button {
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    padding: 10px 8px 8px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: bolder;
    border: none;
    margin: 0 12px;
}

button:hover {
    cursor: pointer;
}

a {
    font-style: inherit;
    font-size: inherit;
    margin-bottom: 30px;
    cursor: pointer;
    color: $action-btn-color !important;
    font-weight: 500;
    text-transform: uppercase;
}

.stacked-input {
    width: 100%;
}

.basic-button {
    margin-top: 1em !important;

    &:last-child {
        margin-top: 8px;
        margin-bottom: 1.5em;
    }
}

@media screen and (max-width: 480px) {
    .screen-container {
        width: 100%;
    }

    .content {
        padding: 0 3%;
    }

    .logo {
        margin: 30px auto 15px;
    }
}
</style>
