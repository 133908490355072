export const categoriesList = {
    faturacartao: '#9f1b0f',
    pets: '#b5b93c',
    compras: '#b14cff',
    cuidadospessoais: '#2dd0b0',
    transporte: '#6db0e5',
    telefonia: '#45b5d8',
    combotvinternettelefone: '#45b5d8',
    credito: '#2980b9',
    contasresidenciais: '#ff4c93',
    saude: '#eb4242',
    educacao: '#576da5',
    lazer: '#4ec4c4',
    servicos: '#e9ba00',
    alimentacao: '#e8b546',
    casa: '#ff4cf3',
    investimento: '#00446c',
    outros: '#e873b0',
    diversosvariavel: '#e873b0',
    objetivo: '#00446c',
    rendafixa: '#2fbc9c',
    salario: '#2fbc9c',
    rendaextra: '#27a085',
    emprestimopessoal: '#55be71',
    rendimento: '#86f0e7',
    beneficio: '#e67e22',
    resgate: '#9b59b6',
    cartao: 'none',
    contabancaria: '#9f261a',
    movimentacaobancaria: '#9f261a',
    objetivos: '#fa6437',
    outrasrendas: '#37cc71',
    mercado: '#dda018',
    assinaturas: '#4c63e0',
    doacoes: '#cf6a87',
    impostos: '#696969',
    investimentos: '#9372ef',
    seguros: '#c651f0',
    servicos: '#9db2d9',
    taxasbancarias: '#c35471',
    trabalho: '#67ba6a',
    viagem: '#82ccdd',
    aluguel: '#e74c3b',
    bonus: '#f1c40d',
    comissao: '#3498db',
    heranca: '#34495e',
    mesada: '#8e44ad',
    presente: '#2dae60',
    juros: '#e6a8a8',
    outrasdividaseparcelas: '#ff7373',
}

export const alphabet = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
]

export const expertizesDictonary = {
    financas: 'finanças',
    dividas: 'dívidas',
}

export const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

export const categoryWithIcon = [
    'estorno',
    'alimentacao',
    'assinaturas',
    'doacoes',
    'impostos',
    'investimentos',
    'juros',
    'seguros',
    'servicos',
    'taxasbancarias',
    'trabalho',
    'viagem',
    'aluguel',
    'bonus',
    'comissao',
    'heranca',
    'mesada',
    'presente',
    'outrasdividaseparcelas',
    'faturacartao',
    'movimentacaobancaria',
    'salario',
    'rendaextra',
    'beneficio',
    'resgate',
    'outrasrendas',
    'credito',
    'casa',
    'alimentacao',
    'educacao',
    'saude',
    'transporte',
    'combotvinternettelefone',
    'contasresidenciais',
    'lazer',
    'compras',
    'cuidadospessoais',
    'pet',
    'emprestimopessoal',
    'diversosvariavel',
]

export const categoriesWithoutIcon = {
    'adiantamentoouvale-revenues': 'salario',
    'decimoterceiro-revenues': 'salario',
    'prolaboreoureceitaviapj-revenues': 'rendimento',
    'pensao-revenues': 'rendaextra',
    'aposentadoria-revenues': 'salario',
    'previdenciaprivada-investiments': 'investimentos',
    'titulodecapitalizacao-investiments': 'investimentos',
    'poupanca-investiments': 'investimentos',
    'consorcio-investiments': 'investimentos',
    'financiamentodeimovel-expenses': 'outrasdividaseparcelas',
    'funcionariodomestico-expenses': 'servicos',
    'condominio-expenses': 'casa',
    'atividadesfisicas-expenses': 'saude',
    'atividadesextras-expenses': 'diversosvariavel',
    'internet-expenses': 'combotvinternettelefone',
    'tv-expenses': 'combotvinternettelefone',
    'telefonefixo-expenses': 'combotvinternettelefone',
    'celular-expenses': 'combotvinternettelefone',
    'associacaoeentidade-expenses': 'doacoes',
    'emprestimoconsignado-expenses': 'emprestimopessoal',
    'cartaodecredito-expenses': 'faturacartao',
    'pensao-expenses': 'outrasdividaseparcelas',
    'emprestimocomterceiros-expenses': 'emprestimopessoal',
    'financiamentodeautomovel-expenses': 'outrasdividaseparcelas',
    'petbanhotosaalimentacao-expenses': 'pet',
    'presentes-expenses': 'outros',
    'diversosfixa-expenses': 'diversosvariavel',
    'carro-expenses': 'transporte',
}

export const sheetPlanningCategories = {
    revenues: [
        'Salário',
        'Adiantamento ou Vale',
        'Pró-labore ou Receita via PJ',
        'Comissão',
        'Benefício (Outros)',
        'Aluguel',
        'Pensão',
        'Bônus',
        'Renda Extra',
        'Outras rendas',
    ],
    fixed: [
        'Condomínio',
        'Luz',
        'Aluguel',
        'Água',
        'Gás',
        'Combo (TV, Internet, Telefone)',
        'Internet',
        'TV',
        'Telefone Fixo',
        'Celular',
        'Funcionário Doméstico (Outros)',
        'Serviços (Outros)',
        'Educação (Outros)',
        'Atividades Físicas (Outros)',
        'Atividades Extras (Outros)',
        'Saúde (Outros)',
        'Taxas bancárias (Outros)',
        'Pet (banho, tosa, alimentação)',
        'Impostos (Outros)',
        'Doações',
        'Assinaturas',
        'Diversos (Fixa)',
    ],
    variable: [
        'Alimentação (Outros)',
        'Transporte (Outros)',
        'Lazer (Outros)',
        'Saúde (Outros)',
        'Compras (Outros)',
        'Pet (Outros)',
        'Diversos (Variável)',
    ],
}
